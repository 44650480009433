<template>
<div class="text-xs text-gray-800 bg-amarelo p-1 mt-2.5 rounded font-bold py-3 mb-2 border border-solid border-roxo z-2">
    <i class="fa fa-info-circle"></i>
    {{ texto }}
</div>
</template>

<script>
export default {
    props: ['texto']
}
</script>

<style scoped>

</style>